import classNames from 'classnames';
import { BaseLink as Link } from '@spikemark/ui/components/navigation/entity-links';
import { FC } from 'react';
import { Route } from './nav-links';

export const NavItem: FC<{
  route: Route;
  className?: string;
  activeClassName?: string;
  isActive?: boolean;
}> = ({ route, className, activeClassName, isActive }) => {
  return (
    <Link
      href={route.path ?? '/'}
      className={classNames(
        'block p-2 lg:px-3 xl:px-4 grid h-full',
        isActive ? activeClassName : className,
        {
          'pointer-events-none': !route.path,
        }
      )}
    >
      {route.title}
    </Link>
  );
};
