import(/* webpackMode: "eager", webpackExports: ["AppFooter"] */ "/app/apps/web/components/app-footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppHeaderContent"] */ "/app/apps/web/components/app-header-content.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppProvider"] */ "/app/apps/web/lib/app-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"styles/fonts/index.ts\",\"import\":\"Gabarito\",\"arguments\":[{\"display\":\"swap\",\"subsets\":[\"latin\"],\"variable\":\"--fontGabarito\",\"weight\":[\"500\",\"600\"]}],\"variableName\":\"fontGabarito\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"styles/fonts/index.ts\",\"import\":\"Nunito_Sans\",\"arguments\":[{\"display\":\"swap\",\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"variable\":\"--fontNunito\",\"weight\":[\"400\",\"600\"]}],\"variableName\":\"fontNunito\"}");
;
import(/* webpackMode: "eager" */ "/app/apps/web/styles/style.css");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider"] */ "/app/packages/ui/components/ui/tooltip.tsx");
