import { BaseLink as Link } from '../components/navigation/entity-links';
import { FC } from 'react';
import { Brand } from './brand';
import { HeaderContainer } from './header-container';
import { DesktopNavContainer } from './navigation/navigation-desktop';
import { NavLinks, Route } from './navigation/nav-links';

type HeaderProps = {
  topLevelNavigation: Route[];
};

export const HeaderDesktop: FC<HeaderProps> = ({ topLevelNavigation }) => {
  return (
    <HeaderContainer
      innerClassName="justify-between"
      className="fixed border-zinc-200 border-b bg-white drop-shadow-sm"
    >
      <Link href="/" className="block shrink-0">
        <Brand />
      </Link>
      <nav className="mx-4 h-full flex">
        <DesktopNavContainer>
          <NavLinks
            routes={topLevelNavigation}
            className="text-primary font-medium h-full hover:bg-zinc-100 transition-colors duration-200 place-items-center"
            activeClassName="text-primary font-medium bg-coolgray-lightest place-items-center"
          />
        </DesktopNavContainer>
      </nav>
    </HeaderContainer>
  );
};
