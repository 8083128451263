'use client';

import { Capacitor } from '@capacitor/core';
import { Route } from '@spikemark/ui/shell/navigation/nav-links';
import { Header } from '@spikemark/ui/shell/header';
import { FC } from 'react';

export const AppHeaderContent: FC<{ routes: Route[] }> = ({ routes }) => {
  return <Header topLevelNavigation={routes} isCapacitor={Capacitor.isNativePlatform()} />;
};
