'use client';

import { FC } from 'react';
import { usePathname } from 'next/navigation';
import { BaseLink as Link } from './navigation/entity-links';
import classNames from 'classnames';
import { Route } from '../shell/navigation/nav-links';
import { CalendarCheck, LucideIcon, ShieldHalf, TableProperties, Trophy } from 'lucide-react';

const nativeFooterNavigation: FooterLinkItem[] = [
  { path: '/results', title: 'Results', icon: TableProperties },
  { path: '/schedule', title: 'Schedule', icon: CalendarCheck },
  { path: '/rankings', title: 'Rankings', icon: Trophy },
  { path: '/teams', title: 'Teams', icon: ShieldHalf },
];

type FooterLinkItem = Route & { icon: LucideIcon };

const FooterLink: FC<FooterLinkItem> = ({ path, title, icon: Icon }) => {
  const pathname = usePathname() ?? '/';
  const active = path && pathname.includes(path);

  return (
    <Link
      href={path ?? '/'}
      className={classNames(
        'flex flex-col items-center text-base appearance-none hocus:appearance-none',
        active ? 'font-bold' : 'font-light'
      )}
    >
      <Icon className="text-white w-6 h-6 mb-1" />
      {title}
    </Link>
  );
};

export const NativeFooter = () => {
  return (
    <>
      <div className="h-28" />
      <footer className="fixed bottom-0 left-0 right-0 z-20 bg-primary text-lg text-white shadow-nav-dark-bg">
        <nav
          className="relative flex items-center w-full max-w-screen-2xl mx-auto"
          style={{ paddingBottom: 'calc(env(safe-area-inset-bottom) - 20px)' }}
        >
          <ul
            role="menubar"
            aria-orientation="horizontal"
            className="flex flex-grow justify-between px-6 pt-5 pb-8"
          >
            {nativeFooterNavigation.map((item) => (
              <FooterLink {...item} />
            ))}
          </ul>
        </nav>
      </footer>
    </>
  );
};
