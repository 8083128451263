import { FC } from 'react';
import { HeaderContainer } from './header-container';
import { BackButton } from './back-button';
import { MobileNavContainer } from './navigation/navigation-mobile';
import { NavLinks, Route } from './navigation/nav-links';
import { BaseLink as Link } from '../components/navigation/entity-links';
import { Brand } from './brand';
import classNames from 'classnames';

type HeaderProps = {
  topLevelNavigation: Route[];
  isCapacitor?: boolean;
};

export const HeaderMobile: FC<HeaderProps> = ({ topLevelNavigation, isCapacitor }) => {
  return (
    <>
      {/* This div is to cover the notch area on iOS */}
      <div
        className="fixed bg-white"
        style={{
          top: -100,
          left: 0,
          right: 0,
          height: 100,
          zIndex: 1000,
        }}
      />
      <HeaderContainer className="z-[51] fixed border-zinc-200 border-b bg-white drop-shadow-sm">
        <div className={classNames('w-[60px]', { hidden: !isCapacitor })}>
          <BackButton />
        </div>
        <div className={classNames('pl-1', { 'flex-1 flex justify-center': isCapacitor })}>
          <Link href="/">
            <Brand variant="small" />
          </Link>
        </div>
        <nav className="w-[76px]">
          <MobileNavContainer>
            <NavLinks
              routes={topLevelNavigation}
              className="text-zinc-200 text-base"
              activeClassName="text-semibold text-clippd-pink text-base"
            />
          </MobileNavContainer>
        </nav>
      </HeaderContainer>
    </>
  );
};
