import classNames from 'classnames';
import Link, { LinkProps as _LinkProps } from 'next/link';
import { FC, PropsWithChildren } from 'react';
import { isMappedPlayer } from '../../utils/player';

type LinkProps = _LinkProps & {
  className?: string;
  disabled?: boolean;
  variant?: 'default' | 'secondary';
};

type EntityLinkProps<T> = PropsWithChildren<T & Omit<LinkProps, 'href'>>;

export const EntityLink: FC<PropsWithChildren<LinkProps>> = ({
  children,
  className,
  variant = 'default',
  disabled,
  ...props
}) =>
  disabled ? (
    children
  ) : (
    <BaseLink
      {...props}
      className={classNames(className, 'leading-none', {
        'text-primary dark:text-white': variant === 'default',
        'text-secondary dark:text-coolgray-mid': variant === 'secondary',
      })}
    >
      {children}
    </BaseLink>
  );

export const PlayerLink: FC<EntityLinkProps<{ playerId?: string }>> = ({
  playerId,
  children,
  ...linkProps
}) => {
  if (!isMappedPlayer(playerId)) {
    return <span className={linkProps.className}>{children}</span>;
  }

  return (
    <EntityLink href={`/players/${playerId}`} {...linkProps}>
      {children}
    </EntityLink>
  );
};

export const TeamLink: FC<EntityLinkProps<{ schoolId: string }>> = ({
  schoolId,
  children,
  ...linkProps
}) => {
  return (
    <EntityLink href={`/teams/${schoolId}`} {...linkProps}>
      {children}
    </EntityLink>
  );
};

export const BaseLink: FC<PropsWithChildren<any>> = ({ children, ...restprops }) => {
  return (
    <Link prefetch={false} {...restprops}>
      {children}
    </Link>
  );
};
